import * as React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import * as intl from "react-intl-universal";
import { EventFormState } from "../../../../model/eventFormState";
import { CompanyState } from "../../../../model/companyState";
import { AuthenticationState } from "../../../../model/authenticationState";
import * as EventFormActions from "../../../../actions/eventForm";
import EventPricingInfoEdit from "./pricing/EventPricingInfoEdit";
import EventBookingInfoEdit from "./pricing/EventBookingInfoEdit";
import AppStyles from "../../../../styles/appStyles";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  authenticationState: AuthenticationState;
}

class EventPriceInfoTab extends React.Component<Props> {
  render() {
    /*
    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <Stack>
        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.pricing_price_info`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventPricingInfoEdit
              eventFormState={this.props.eventFormState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>

        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.pricing_booking_info`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventBookingInfoEdit
              eventFormState={this.props.eventFormState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>

        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.pricing_discount_info`)}</b>
          </Typography>
        </Grid>
      </Stack>
    );
  }
}

export default EventPriceInfoTab;
