import { Stack } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AppStyles from "../../styles/appStyles";
import { DefaultButton } from "../../components/Buttons";
import * as React from "react";
import intl from "react-intl-universal";

interface Props {
  onSave: () => void;
  readonly: boolean;
  isCreate: boolean;
}

export default function FamilyBonusClubHeaderActions({ onSave, readonly, isCreate }: Props) {
  return (
    <Stack direction={"row"} gap={"24px"}>
      <DefaultButton color={"success"} disabled={readonly} onClick={onSave}>
        {isCreate && <AddCircleIcon sx={AppStyles.buttonLeftIcon} />}
        {!isCreate && <SaveIcon sx={AppStyles.buttonLeftIcon} />}
        {isCreate
          ? intl.get("family_bonus_club.offer_form.action_create")
          : intl.get("family_bonus_club.offer_form.action_edit")}
      </DefaultButton>
    </Stack>
  );
}
