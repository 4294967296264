import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { asNonEmptyString, cloneDefaultState, isNonEmptyNumber, updateStateFieldByProp } from "../util/util_extensions";
import { EventFormState } from "../model/eventFormState";
import {
  allowedEventMonthValues,
  BCAvailabilityRangeType,
  BCEvent,
  BCEventAvailabilityRange,
  BCEventBookingUrlType,
  BCEventCategory,
  BCEventDedicatedTime,
  BCEventRangeOpeningHours,
  BCEventTag,
  BCEventType,
} from "../model/event";
import { Media } from "../model/media";
import { Company } from "../model/company";
import { BCLocation } from "../model/location";
import { CompanySharedLocation } from "../model/company.shared.location";
import * as EmailValidator from "email-validator";
import * as intl from "react-intl-universal";

const moment = require("moment");

function validateEventFormData(newState: any): string[] {
  const validationErrors: string[] = [];
  if (!isNonEmptyNumber(newState.event.age_from)) {
    validationErrors.push(intl.get("event_form.validation.missing_age_from"));
  }
  if (!isNonEmptyNumber(newState.event.age_to)) {
    validationErrors.push(intl.get("event_form.validation.missing_age_to"));
  }
  if (
    isNonEmptyNumber(newState.event.age_from) &&
    isNonEmptyNumber(newState.event.age_to) &&
    newState.event.age_from > newState.event.age_to
  ) {
    validationErrors.push(intl.get("event_form.validation.age_from_bigger_than_to"));
  }
  if (!asNonEmptyString(newState.event.title)) {
    validationErrors.push(intl.get("event_form.validation.missing_title"));
  }
  if (!asNonEmptyString(newState.event.summary)) {
    validationErrors.push(intl.get("event_form.validation.missing_summary"));
  }
  if (!asNonEmptyString(newState.event.description)) {
    validationErrors.push(intl.get("event_form.validation.missing_description"));
  }
  if (newState.event.categories == null || newState.event.categories.length <= 0) {
    validationErrors.push(intl.get("event_form.validation.missing_categories"));
  }
  if (newState.event.locations.length === 0) {
    validationErrors.push(intl.get("event_form.validation.missing_locations"));
  }
  for (const location of newState.event.locations) {
    let hasAddedInvalidAddressError = false;
    if (location.company_shared_location || location.shared_location_id) {
    } else {
      if (
        !asNonEmptyString(location.address) ||
        !asNonEmptyString(location.postal_code) ||
        !asNonEmptyString(location.city) ||
        !asNonEmptyString(location.country_code)
      ) {
        if (!hasAddedInvalidAddressError) {
          validationErrors.push(intl.get("event_form.validation.missing_valid_locations"));
          hasAddedInvalidAddressError = true;
        }
      }
    }
  }
  if (newState.event.tags == null || newState.event.tags.length <= 0) {
    validationErrors.push(intl.get("event_form.validation.missing_tags"));
  }
  if (newState.event.contact_email !== "" && !EmailValidator.validate(newState.event.contact_email)) {
    validationErrors.push(intl.get("event_form.validation.contact_email_invalid"));
  }
  if (newState.event.email !== "" && !EmailValidator.validate(newState.event.email)) {
    validationErrors.push(intl.get("event_form.validation.event_email_invalid"));
  }
  if (
    newState.event.booking_url !== "" &&
    newState.event.booking_url_type === BCEventBookingUrlType.Email &&
    !EmailValidator.validate(newState.event.booking_url)
  ) {
    validationErrors.push(intl.get("event_form.validation.booking_url_invalid"));
  }
  if (newState.event.type === BCEventType.Recurring) {
    if (!newState.event.event_range_type) {
      validationErrors.push(intl.get("event_form.validation.missing_opening_hours"));
    }
  } else {
    if (newState.event.dedicated_times.length === 0) {
      validationErrors.push(intl.get("event_form.validation.missing_opening_hours"));
    }
  }
  if (newState.event.media_items.length === 0) {
    validationErrors.push(intl.get("event_form.validation.missing_images"));
  }

  return validationErrors;
}

const defaultState = {
  error: null,
  event_id: null,
  valid: false,
  dirty: false,
  categories: null,
  tags: null,
  sharedLocations: null,
  readonly: false,
  closePage: false,
  validation_errors: [],
  event: {
    event_range_type: null,
    type: BCEventType.Recurring,
    reject_reason: "",
    promotion_months: allowedEventMonthValues.join(","),
    title: "",
    summary: "",
    description: "",
    price_in_cents: null,
    price_in_cents_3yr: null,
    price_in_cents_8yr: null,
    price_in_cents_adult: null,
    is_starting_price: false,
    price_on_request: false,
    price_currency: "EUR",
    is_registration_necessary: false,
    location_independent: false,
    phone: "",
    website_url: null,
    age_from: null,
    age_to: null,
    status: null,
    categories: [],
    tags: [],
    event_availability_ranges: [],
    locations: [],
    dedicated_times: [],
    media_items: [],
    is_ready_to_review: false,
    not_reviewable_reasons: [],
    is_indoor_event: false,
    is_outdoor_event: false,
    booking_url_type: BCEventBookingUrlType.Link,
    phone_alternative: "",
    fax: "",
    destination_type: "",
    destination_subtype: "",
    weather_conditions: "",
    is_winter_destination: false,
    is_accessible: false,
    stroller_condition: "",
    is_dogs_allowed: "",
    notes_opening_hours: "",
    notes_pricing: "",
    main_theme: "",
    number_of_animal_species: null,
    activity_duration: "",
    is_available_for_kids_birthday_parties: false,
    is_restaurant_available: false,
    is_snackbar_available: false,
    distance_supply: null,
    is_toilet_available: false,
    is_diaper_changing_available: false,
    distance_public_transport: null,
    reachability_options: "",
    is_parking_available: false,
    number_of_parking_spots: null,
    surroundings_type: "",
    shade_type: "",
    price_level: "",
    type_of_event: "",
    event_highlights: "",
    is_online_event: false,
    is_hosted_with_bad_weather: false,
    event_duration: "",
    is_childcare_available: false,
    contact_title: "",
    contact_firstname: "",
    contact_lastname: "",
    contact_email: "",
    email: "",
    responsible_organisation: "",
    has_school_offers: false,
    video_link: "",
  },
};

export const eventFormState = createReducer(cloneDefaultState(defaultState), {
  [ActionType.RESET_EVENT_FORM]() {
    return cloneDefaultState(defaultState);
  },
  [ActionType.CREATE_EVENT](state: EventFormState, action: Action<any>) {
    const newState = cloneDefaultState(defaultState);
    if (action.payload.company) {
      const { company } = action.payload;
      newState.event.phone = company.phone;
      newState.event.phone_alternative = company.phone;
      newState.event.fax = company.fax ?? "";
      newState.event.email = company.email ?? "";
      newState.event.contact_title = company.sales_contact_title ?? "";
      newState.event.contact_firstname = company.sales_contact_firstname;
      newState.event.contact_lastname = company.sales_contact_lastname ?? "";
      newState.event.contact_email = company.sales_contact_email ?? "";
      newState.event.website_url = company.website_url;
      newState.event.price_currency = company.default_currency;
      newState.event.responsible_organisation = company.name;
    }

    const validationErrors = validateEventFormData(newState);

    return {
      ...newState,
      valid: validationErrors.length === 0,
      validation_errors: validationErrors,
      categories: action.payload.categories,
      tags: action.payload.tags,
      sharedLocations: action.payload.sharedLocations,
    };
  },
  [ActionType.EDIT_EVENT](state: EventFormState, action: Action<any>) {
    if (action.payload.data === true) {
      const newState = {
        event_id: action.payload.event_id,
        event: action.payload.event,
        categories: action.payload.categories,
        tags: action.payload.tags,
        sharedLocations: action.payload.sharedLocations,
        valid: true,
        validation_errors: [],
        readonly: action.payload.readonly,
      };

      if (newState.event.dedicated_times == null) {
        newState.event.dedicated_times = [];
      }
      if (newState.event.event_availability_ranges == null) {
        newState.event.event_availability_ranges = [];
      }

      const ranges: BCEventAvailabilityRange[] = newState.event.event_availability_ranges;
      let allYear = true;
      let seasonal = true;
      if (ranges && ranges.length > 0) {
        for (const availabilityRange of ranges) {
          if (!availabilityRange.is_all_year) {
            allYear = false;
          }
          if (!availabilityRange.is_seasonal_range) {
            seasonal = false;
          }
        }
      } else {
        allYear = true;
      }
      if (allYear) {
        newState.event.event_range_type = BCAvailabilityRangeType.AllYear;
      } else if (seasonal) {
        newState.event.event_range_type = BCAvailabilityRangeType.Seasonal;
      } else {
        newState.event.event_range_type = BCAvailabilityRangeType.Individual;
      }

      const validationErrors = validateEventFormData(newState);
      return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
    }
    return { ...state };
  },
  [ActionType.VALIDATE_EVENT_FORM](state: EventFormState) {
    const newState = { ...state };
    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.UPDATE_EVENT_FORM_FIELD](state: EventFormState, action: Action<any>) {
    const newState = updateStateFieldByProp(
      { ...state, error: null, dirty: true },
      action.payload.prop,
      action.payload.value,
    );

    if (action.payload.prop === "event.event_range_type") {
      if (action.payload.value === BCAvailabilityRangeType.AllYear) {
        if (newState.event.event_availability_ranges && newState.event.event_availability_ranges.length >= 1) {
          const firstRange: BCEventAvailabilityRange = newState.event.event_availability_ranges[0];
          const range: BCEventAvailabilityRange = {
            start_date: moment("1900-01-01").toDate(),
            end_date: moment("1900-12-31").toDate(),
            is_all_year: true,
            is_seasonal_range: true,
            opening_hours: firstRange.opening_hours,
            event_availability_range_id: firstRange.event_availability_range_id,
          };
          newState.event.event_availability_ranges = [range];
        } else if (!newState.event.event_availability_ranges || newState.event.event_availability_ranges.length === 0) {
          newState.event.event_availability_ranges = [
            {
              start_date: moment("1900-01-01").toDate(),
              end_date: moment("1900-12-31").toDate(),
              is_seasonal_range: true,
              is_all_year: true,
              opening_hours: [{}],
            },
          ];
        }
        newState.event.promotion_months = "jan,feb,mar,apr,may,jun,jul,aug,sep,oct,nov,dec";
      } else if (action.payload.value === BCAvailabilityRangeType.Seasonal) {
        for (const range of newState.event.event_availability_ranges) {
          range.is_all_year = false;
          range.is_seasonal_range = true;
          if (!range.start_date) {
            range.start_date = new Date();
          }
        }
      } else if (action.payload.value === BCAvailabilityRangeType.Individual) {
        for (const range of newState.event.event_availability_ranges) {
          range.is_all_year = false;
          range.is_seasonal_range = false;
          range.start_date = new Date();
          range.end_date = new Date();
        }
      }
    }

    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.UPDATE_COMPANY_MEDIA_ITEM](state: EventFormState, action: Action<any>) {
    if (action.payload.media_item) {
      const newState = { ...state, dirty: true };
      const index = newState.event.media_items.findIndex(
        (value) => value.media_id === action.payload.media_item.media_id,
      );
      newState.event.media_items[index] = action.payload.media_item;
      return newState;
    }
    return state;
  },
  [ActionType.ADD_EVENT_DEDICATED_TIME](state: EventFormState) {
    const newState = { ...state, dirty: true };

    if (newState.event.dedicated_times == null) {
      newState.event.dedicated_times = [];
    }
    newState.event.dedicated_times.push({});

    return newState;
  },
  [ActionType.REMOVE_EVENT_DEDICATED_TIME](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };

    const time: BCEventDedicatedTime = action.payload;
    const index = newState.event.dedicated_times.indexOf(time);
    newState.event.dedicated_times.splice(index, 1);

    return newState;
  },
  [ActionType.ADD_EVENT_OPENING_HOUR](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };

    const range: BCEventAvailabilityRange = action.payload;
    const index = newState.event.event_availability_ranges?.indexOf(range);
    if (index !== undefined && index >= 0) {
      newState.event.event_availability_ranges[index].opening_hours.push({});
    }
    return newState;
  },
  [ActionType.UPDATE_EVENT_OPENING_HOUR](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };

    const range: BCEventAvailabilityRange = action.payload.range;
    const value: BCEventRangeOpeningHours = action.payload.value;
    const index: number = action.payload.index;

    const rangeIndex = newState.event.event_availability_ranges?.indexOf(range);
    if (rangeIndex !== undefined && rangeIndex >= 0) {
      newState.event.event_availability_ranges[rangeIndex].opening_hours[index] = value;
    }
    return newState;
  },
  [ActionType.REMOVE_EVENT_OPENING_HOUR](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };
    const range: BCEventAvailabilityRange = action.payload.range;
    const hour: BCEventRangeOpeningHours = action.payload.hour;
    const index = range.opening_hours.indexOf(hour);
    range.opening_hours.splice(index, 1);
    return newState;
  },
  [ActionType.ADD_EVENT_AVAILABILITY_RANGE](state: EventFormState) {
    const newState = { ...state, dirty: true };
    if (!newState.event.event_availability_ranges) {
      newState.event.event_availability_ranges = [];
    }
    if (newState.event.event_range_type === BCAvailabilityRangeType.Seasonal) {
      newState.event.event_availability_ranges.push({
        is_seasonal_range: true,
        is_all_year: false,
        start_date: new Date(),
        opening_hours: [{}],
      });
    } else if (newState.event.event_range_type === BCAvailabilityRangeType.Individual) {
      newState.event.event_availability_ranges.push({
        is_seasonal_range: false,
        is_all_year: false,
        start_date: new Date(),
        opening_hours: [{}],
      });
    }
    return newState;
  },
  [ActionType.REMOVE_EVENT_AVAILABILITY_RANGE](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };
    const range: BCEventAvailabilityRange = action.payload;
    const index = newState.event.event_availability_ranges?.indexOf(range);
    if (index !== undefined && index >= 0) {
      newState.event.event_availability_ranges.splice(index, 1);
    }
    return newState;
  },
  [ActionType.UPDATE_AVAILABILITY_RANGE](state: EventFormState) {
    const newState = { ...state, dirty: true };
    let promotionMonthsToSelect = [];
    for (const range of newState.event.event_availability_ranges) {
      if (range.is_all_year) {
        promotionMonthsToSelect = allowedEventMonthValues;
      } else {
        if (range.start_date && range.end_date) {
          const startMonthIndex = Number(moment(range.start_date).format("M")) - 1;
          const endMonthIndex = Number(moment(range.end_date).format("M")) - 1;
          if (startMonthIndex <= endMonthIndex) {
            for (const month of allowedEventMonthValues.slice(startMonthIndex, endMonthIndex + 1)) {
              if (promotionMonthsToSelect.indexOf(month) === -1) {
                promotionMonthsToSelect.push(month);
              }
            }
          } else {
            for (const month of allowedEventMonthValues.slice(0, endMonthIndex + 1)) {
              if (promotionMonthsToSelect.indexOf(month) === -1) {
                promotionMonthsToSelect.push(month);
              }
            }

            for (const month of allowedEventMonthValues.slice(startMonthIndex)) {
              if (promotionMonthsToSelect.indexOf(month) === -1) {
                promotionMonthsToSelect.push(month);
              }
            }
          }
        }
      }
    }
    newState.event.promotion_months = promotionMonthsToSelect.join(",");
    return newState;
  },
  [ActionType.ADD_COMPANY_ADDRESS_AS_LOCATION](state: EventFormState, action: Action<any>) {
    const company: Company = action.payload;
    const newState = { ...state, dirty: true };

    const newLocation = {
      directions: "",
      address: company.address,
      postal_code: company.postal_code,
      city: company.city,
      country: company.country,
      country_code: company.country_code,
      district: "",
      additional_info: "",
      longitude: company.longitude,
      latitude: company.latitude,
    };
    newState.event.locations.push(newLocation);

    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.SET_COMPANY_CONTACT_AS_EVENT_CONTACT](state: EventFormState, action: Action<any>) {
    const company: Company = action.payload;

    const newState = {
      ...state,
      dirty: true,
      event: {
        ...state.event,
        phone: company.phone ?? "",
        phone_alternative: company.phone_2 ?? "",
        fax: company.fax ?? "",
        website_url: company.website_url ?? "",
        email: company.email ?? "",
      },
    };

    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.SET_COMPANY_CONTACT_PERSON_AS_EVENT_CONTACT_PERSON](state: EventFormState, action: Action<any>) {
    const company: Company = action.payload;
    const newState = {
      ...state,
      dirty: true,
      event: {
        ...state.event,
        contact_title: company.sales_contact_title ?? "",
        contact_firstname: company.sales_contact_firstname,
        contact_lastname: company.sales_contact_lastname ?? "",
        contact_email: company.sales_contact_email ?? "",
      },
    };

    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ActionType.ADD_LOCATION](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };

    newState.event.locations.push({
      directions: "",
      address: "",
      postal_code: "",
      city: "",
      country: "",
      country_code: "",
      district: "",
      additional_info: "",
    });
    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.ADD_SHARED_LOCATION](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };
    const locations: CompanySharedLocation[] = action.payload;
    const newLocations = locations.map((value) => {
      return {
        shared_location_id: value.location_id,
        company_shared_location: value,
      };
    });
    newState.event.locations.push(...newLocations);
    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ActionType.UPDATE_LOCATION](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };
    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.REMOVE_LOCATION](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };
    const location: BCLocation = action.payload;
    const index = newState.event.locations.indexOf(location);
    newState.event.locations.splice(index, 1);
    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.ADD_EVENT_MEDIA_ITEM](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };

    const mediaItem: Media = action.payload;
    newState.event.media_items.push(mediaItem);

    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.REMOVE_EVENT_MEDIA_ITEM](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };

    const mediaItem: Media = action.payload;
    const index = newState.event.media_items.indexOf(mediaItem);
    newState.event.media_items.splice(index, 1);

    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.TOGGLE_EVENT_CATEGORY](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };

    const category: BCEventCategory = action.payload;
    const index = newState.event.categories.findIndex((cat) => cat.category_id === category.category_id);
    if (index === -1) {
      newState.event.categories.push(category);
    } else {
      newState.event.categories.splice(index, 1);
    }

    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.REMOVE_TAG](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };

    const tag: BCEventTag = action.payload;
    const index = newState.event.tags.findIndex((t) => t.tag_id === tag.tag_id);
    if (index !== -1) {
      newState.event.tags.splice(index, 1);
    }

    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.ADD_TAG](state: EventFormState, action: Action<any>) {
    const newState = { ...state, dirty: true };

    const tag: BCEventTag = action.payload;
    const index = newState.event.tags.findIndex((t) => t.tag_id != null && t.tag_id === tag.tag_id);
    if (index === -1) {
      if (tag.tag_id === undefined) {
        const fuzzyIndex = newState.event.tags.findIndex((t) => t.name.toLowerCase() === tag.name.trim().toLowerCase());
        if (fuzzyIndex === -1) {
          newState.event.tags.push(tag);
        }
      } else {
        newState.event.tags.push(tag);
      }
    }

    const validationErrors = validateEventFormData(newState);
    return { ...newState, valid: validationErrors.length === 0, validation_errors: validationErrors };
  },
  [ActionType.SAVE_EVENT](state: EventFormState, action: Action<any>) {
    const oldEventRangeType = state.event.event_range_type;
    return {
      ...state,
      event: { ...action.payload.event, event_range_type: oldEventRangeType },
      event_id: action.payload.event.event_id,
      dirty: false,
    };
  },
  [ActionType.UPDATE_EVENT_STATUS](state: EventFormState, action: Action<any>) {
    const oldEventRangeType = state.event.event_range_type;
    return {
      ...state,
      event: { ...action.payload.event, event_range_type: oldEventRangeType },
    };
  },
  [ActionType.UPDATE_EVENT_STATUS_BULK](state: EventFormState, action: Action<any>) {
    const oldEventRangeType = state.event.event_range_type;

    if (action.payload.data === true) {
      const { events } = action.payload;
      const newEvent = (events as BCEvent[]).find((event) => event.event_id === state.event_id);
      return { ...state, event: { ...newEvent, event_range_type: oldEventRangeType } };
    }
    return state;
  },
  [ActionType.LOAD_HERO_FORM](state: EventFormState, action: Action<any>) {
    return {
      ...state,
      event: { ...state.event, booked_hero_slots: action.payload.booked_slots },
    };
  },
  [ActionType.PAGE_SAVING_ERROR](state: EventFormState, action: Action<any>) {
    return { ...state, error: action.payload.error };
  },
});
