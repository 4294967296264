import { Grid, MenuItem, Paper, Select } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import AppStyles from "../../styles/appStyles";
import { SectionLabel } from "../../components/SectionLabel";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";

const classes = {
  select: {
    paddingTop: "6px",
    paddingBottom: "7px",
    marginTop: "16px",
  },
};

interface Props {
  rejectionDescription: string;
  onRejectionDescriptionChanged: (newValue: string) => void;
}

export default function FamilyBonusClubReviewForm({ rejectionDescription, onRejectionDescriptionChanged }: Props) {
  return (
    <Grid item xs={12}>
      <Paper sx={AppStyles.innerContentPaper}>
        <Grid item container direction="row" spacing={3} sx={AppStyles.detailForm}>
          <Grid item container direction="column" sx={AppStyles.noRightPadding}>
            <SectionLabel required={false}>{intl.get("family_bonus_club.review.add_reject_reason")}</SectionLabel>
            <Select
              onChange={(event) => {
                const reason = event.target.value as string;
                onRejectionDescriptionChanged(
                  `${intl.get(`family_bonus_club.review.reject_reasons.${reason}.long`)}\n\n${rejectionDescription}`,
                );
              }}
              displayEmpty
              sx={classes.select}
              inputProps={{
                name: "reject_reasons",
                id: "reject_reasons",
              }}
            >
              <MenuItem value="offer_not_clear">
                {intl.get("family_bonus_club.review.reject_reasons.offer_not_clear.short")}
              </MenuItem>
              <MenuItem value="invalid_offer_conditions">
                {intl.get("family_bonus_club.review.reject_reasons.invalid_offer_conditions.short")}
              </MenuItem>
              <MenuItem value="offer_too_young_to_change">
                {intl.get("family_bonus_club.review.reject_reasons.offer_too_young_to_change.short")}
              </MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} container direction="column">
            <TextFieldWithLabel
              value={rejectionDescription}
              onChange={(event: any) => {
                onRejectionDescriptionChanged(event.target.value);
              }}
              required
              label={intl.get("family_bonus_club.review.rejection_description")}
              id="description"
              multiline
              rows={10}
              margin="normal"
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
