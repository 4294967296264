import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { PageTracker } from "../../util/pageTracker";
import FamilyBonusClubReviewForm from "./FamilyBonusClubReviewForm";
import { Box, Stack, Tabs } from "@mui/material";
import FamilyBonusClubReviewHeaderActions from "./FamilyBonusClubReviewHeaderActions";
import { changedOfferFields, FbcOffer, FbcOfferStatus } from "../../model/fbc/fbc.offer";
import { FamilyBonusClubOfferForm } from "./FamilyBonusClubOfferForm";
import * as FamilyBonusClubActions from "../../actions/family.bonus.club.actions";
import { useDispatch } from "react-redux";
import AppStyles from "../../styles/appStyles";
import intl from "react-intl-universal";
import TabGeneralIcon from "../../assets/icons/TabGeneral";
import EventFormTab from "../../components/eventEdit/EventFormTab";

export function FamilyBonusClubReviewPage() {
  const [rejectionDescription, setRejectionDescription] = useState("");
  const [offer, setOffer] = useState<FbcOffer>();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const params = useParams();

  const offerId = params.offer_id;

  useEffect(() => {
    if (offerId) {
      dispatch<any>(
        FamilyBonusClubActions.loadOfferForReview({
          offer_id: offerId,
          completion: (loadedOffer) => {
            setOffer(loadedOffer);
          },
        }),
      );
    }
  }, []);

  function closePage(): void {
    navigate(-1);
  }

  const [tab, setTab] = useState(0);
  function changeTab(event: any, newTab: any) {
    setTab(newTab);
  }

  return (
    <>
      <PageTracker />
      {offer && (
        <>
          <Box sx={AppStyles.innerContentRootWithoutLimit}>
            <Stack>
              <FamilyBonusClubReviewHeaderActions
                onReviewFinished={closePage}
                rejectionDescription={rejectionDescription}
                offerId={offerId}
                companyId={offer.company_id}
                reviewReason={offer.review.review_reason}
                isOfferUpdate={offer.published_offer !== undefined}
              />
              <FamilyBonusClubReviewForm
                rejectionDescription={rejectionDescription}
                onRejectionDescriptionChanged={setRejectionDescription}
              />
            </Stack>
          </Box>
          {offer.published_offer && offer.draft_offer && offer.draft_offer.status !== FbcOfferStatus.inactive && (
            <Stack direction={"row"} justifyContent={"center"} sx={{ margin: 0 }}>
              <Tabs
                sx={AppStyles.innerContentPaper}
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={changeTab}
                variant={"scrollable"}
                scrollButtons="auto"
              >
                <EventFormTab icon={<TabGeneralIcon />} label={intl.get("family_bonus_club.tabs.tab_draft_version")} />
                <EventFormTab
                  icon={<TabGeneralIcon />}
                  label={intl.get("family_bonus_club.tabs.tab_published_version")}
                />
              </Tabs>
            </Stack>
          )}
          {tab === 0 && offer.draft_offer && offer.draft_offer.status !== FbcOfferStatus.inactive && (
            <FamilyBonusClubOfferForm
              readonly={true}
              offerData={offer.draft_offer}
              maxUsagesUnlimited={offer.draft_offer.max_usages_per_card === -1}
              maxUsagesPerDayUnlimited={offer.draft_offer.max_usages_per_card_per_day === -1}
              markFieldsAsChanged={changedOfferFields({ offer })}
            />
          )}
          {((tab === 1 && offer.published_offer) ||
            (tab === 0 && offer.draft_offer && offer.draft_offer.status === FbcOfferStatus.inactive)) && (
            <FamilyBonusClubOfferForm
              readonly={true}
              offerData={offer.published_offer}
              maxUsagesUnlimited={offer.published_offer.max_usages_per_card === -1}
              maxUsagesPerDayUnlimited={offer.published_offer.max_usages_per_card_per_day === -1}
            />
          )}
        </>
      )}
    </>
  );
}
