import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { CompanyFormState } from "../model/companyFormState";
import { asNonEmptyString, cloneDefaultState, updateStateFieldByProp } from "../util/util_extensions";
import * as EmailValidator from "email-validator";

const defaultState = {
  error: null,
  company_id: null,
  valid: false,
  company: {
    name: "",
    company_id: "",
    email: "",
    phone: "",
    website_url: "",
    uid: "",
    address: "",
    city: "",
    postal_code: "",
    country: "",
    default_currency: "EUR",
    reseller_user_id: null,
    email_add_as_admin: false,
    editorial: false,
    invoice_attention_of: "",
    invoice_addendum: "",
    family_bonuscard: {
      has_joined_fbc: false,
    },
  },
  resellers: [],
  closePage: false,
};

export const companyFormState = createReducer(cloneDefaultState(defaultState), {
  [ActionType.RESET_COMPANY_FORM]() {
    return cloneDefaultState(defaultState);
  },
  [ActionType.EDIT_COMPANY](state: CompanyFormState, action: Action<any>) {
    if (action.payload.data === true) {
      return {
        company_id: action.payload.company_id,
        company: action.payload.company,
        valid: true,
        resellers: action.payload.resellers,
      };
    }
    return { ...state };
  },
  [ActionType.CREATE_COMPANY](state: CompanyFormState, action: Action<any>) {
    return {
      ...cloneDefaultState(defaultState),
      resellers: action.payload.resellers,
    };
  },
  [ActionType.UPDATE_COMPANY_FORM_FIELD](state: CompanyFormState, action: Action<any>) {
    let newState = updateStateFieldByProp({ ...state, error: null }, action.payload.prop, action.payload.value);

    if (!asNonEmptyString(newState.company.name)) {
      newState = { ...newState, valid: false };
    } else if (asNonEmptyString(newState.company.email) && !EmailValidator.validate(newState.company.email)) {
      newState = { ...newState, valid: false };
    } else if (
      asNonEmptyString(newState.company.sales_contact_email) &&
      !EmailValidator.validate(newState.company.sales_contact_email)
    ) {
      newState = { ...newState, valid: false };
    } else {
      newState = { ...newState, valid: true };
    }

    return newState;
  },
  [ActionType.ADD_COMPANY_CUSTOM_CATEGORY](state: CompanyFormState, action: Action<any>) {
    const newState = { ...state };
    newState.company.custom_categories.push(action.payload);
    return newState;
  },
  [ActionType.REMOVE_COMPANY_CUSTOM_CATEGORY](state: CompanyFormState, action: Action<any>) {
    const newState = { ...state };
    const categoryIndex = newState.company.custom_categories.findIndex(
      (value) => value.category_id === action.payload.category_id,
    );
    if (categoryIndex !== -1) {
      newState.company.custom_categories.splice(categoryIndex, 1);
    }
    return newState;
  },
  [ActionType.DELETE_COMPANY](state: CompanyFormState) {
    return { ...state, closePage: true };
  },
  [ActionType.SAVE_COMPANY](state: CompanyFormState) {
    return { ...state, closePage: true };
  },
  [ActionType.PAGE_SAVING_ERROR](state: CompanyFormState, action: Action<any>) {
    return { ...state, error: action.payload.error };
  },
});
