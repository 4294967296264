import { BCEvent } from "../model/event";
import { FbcOffer, FbcOfferStatus } from "../model/fbc/fbc.offer";

export const asNonEmptyString = (value: string | null | undefined): string | null => {
  if (value !== null && value !== undefined && value.length > 0) {
    return value;
  }
  return null;
};

export const isNonEmptyNumber = (value: number | null | undefined): boolean => {
  if (value == null || isNaN(value)) {
    return false;
  }
  const anyValue: any = value;
  if (anyValue === "") {
    return false;
  }
  return true;
};

export const isNonEmptyAndZeroNumber = (value: number | null | undefined): boolean => {
  if (value === null || value === undefined) {
    return false;
  }
  const anyValue: any = value;
  if (anyValue === "") {
    return false;
  }
  if (value === 0) {
    return false;
  }
  return true;
};

export const isNonEmptyAndZeroToHundredNumber = (value: number | null | undefined): boolean => {
  if (value === null || value === undefined) {
    return false;
  }
  const anyValue: any = value;
  if (anyValue === "") {
    return false;
  }
  if (value < 0 || value > 100) {
    return false;
  }
  return true;
};

export const updateStateFieldByProp = (state: any, prop: string, value: any) => {
  const newState = state;
  if (prop.indexOf(".") !== -1) {
    const paths = prop.split(".");
    if (paths.length === 2) {
      if (newState[paths[0]]) {
        newState[paths[0]][paths[1]] = value;
      } else {
        newState[paths[0]] = { [paths[1]]: value };
      }
    } else if (paths.length === 3) {
      if (newState[paths[0]]) {
        if (newState[paths[0]][paths[1]]) {
          newState[paths[0]][paths[1]][paths[2]] = value;
        } else {
          newState[paths[0]][paths[1]] = { [paths[2]]: value };
        }
      } else {
        newState[paths[0]] = { [paths[1]]: { [paths[2]]: value } };
      }
    }
  } else {
    newState[prop] = value;
  }

  return newState;
};

// if we return a default state in a reducer and this state contains nested objects these nested objects would not be replaced but just changed!
// i.e. if we want to reset a redux state to the default state we have to make a deep copy of the default state and return this cloned state.
// Otherwise nested objects would not be reset to the default state but would still contain the modified values.
export function cloneDefaultState(object: any) {
  return simpleClone(object);
}

export function simpleClone(object: any) {
  return JSON.parse(JSON.stringify(object));
}

declare global {
  interface String {
    isEmpty(): boolean;
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.isEmpty = function (): boolean {
  if (this !== null && this !== undefined && this.length > 0) {
    return false;
  }
  return true;
};

export function getParamValue(paramName: any) {
  const url = window.location.search.substring(1); // get rid of "?" in querystring
  const qArray = url.split("&"); // get key-value pairs
  for (let i = 0; i < qArray.length; i++) {
    const pArr = qArray[i].split("="); // split key and value
    if (pArr[0] === paramName) {
      return pArr[1];
    }
  }
  return null;
}

export function convertPriceFromBackend({ price }: { price?: number }): number {
  if (price) {
    const formattedPrice = `${price}`;
    if (formattedPrice.length > 2) {
      return Number(
        `${formattedPrice.slice(0, formattedPrice.length - 2)}.${formattedPrice.slice(formattedPrice.length - 2)}`,
      );
    } else if (formattedPrice.length === 2) {
      return Number(`0.${formattedPrice}`);
    } else if (formattedPrice.length === 1) {
      return Number(`0.0${formattedPrice}`);
    }
  }
  return undefined;
}

export function convertPriceForBackend({ price }: { price?: number }): number {
  if (price) {
    const formattedPrice = price.toLocaleString("en-us", {
      minimumFractionDigits: 2,
    });
    const modifiedValue = formattedPrice.replace(".", "").replace(",", "");
    return Number(modifiedValue);
  }
  return undefined;
}

export function activeOrPendingFamilyBonusClubOffers({ event }: { event: BCEvent }): FbcOffer[] {
  return event.offers.filter((value) => {
    if (value.published_offer && value.published_offer.status === FbcOfferStatus.active) {
      return true;
    }
    if (value.draft_offer && value.draft_offer.status === FbcOfferStatus.active) {
      return true;
    }
    if (value.review) {
      return true;
    }
    return false;
  });
}

export function eventHasActiveOrPendingFamilyBonusClubOffers({ event }: { event: BCEvent }): boolean {
  if (event.offers) {
    return activeOrPendingFamilyBonusClubOffers({ event }).length > 0;
  }
  return false;
}
