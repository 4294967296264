import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { EventState } from "../model/eventState";

export const inboxState = createReducer(
  {
    paging: {
      page_size: 10,
      current_page: 1,
    },
    events: [],
  },
  {
    [ActionType.FETCH_INBOX_EVENTS](state: EventState, action: Action<any>) {
      if (action.payload.data === true) {
        if (action.payload.overview === true) {
          return {
            ...state,
            paging: {
              page_size: state.paging.page_size,
              current_page: state.paging.current_page,
              total_pages: state.paging.total_pages,
              total_size: action.payload.paging.total_size,
            },
          };
        }
        return {
          ...state,
          events: action.payload.events,
          paging: action.payload.paging,
        };
      }
      return { ...state };
    },
    [ActionType.FETCH_FAMILY_BONUS_CLUB_INBOX_EVENTS](state: EventState, action: Action<any>) {
      return { ...state, familyBonusClubInboxCount: action.payload.count };
    },
    [ActionType.REVIEWED_FAMILY_BONUS_CLUB_OFFER](state: EventState) {
      return { ...state, familyBonusClubInboxCount: ((state as any).familyBonusClubInboxCount ?? 0) - 1 };
    },
    [ActionType.EVENT_REVIEW](state: EventState, action: Action<any>) {
      const newState = { ...state };
      const eventIndex = newState.events.findIndex((value) => value.event_id === action.payload.event_id);
      newState.events.splice(eventIndex, 1);
      return newState;
    },
  },
);
