import * as React from "react";
import { Button, ButtonProps, SxProps, Theme } from "@mui/material";
import { Link } from "react-router-dom";
import BackIcon from "@mui/icons-material/ArrowBackIos";

export const DefaultButton = function (props: ButtonProps) {
  const { title, children, size, ...copiedProps } = props;

  const textOnly = Boolean(props.title);

  return (
    <Button size={size ? size : "large"} {...copiedProps}>
      {textOnly ? title : children}
    </Button>
  );
};

interface LinkButtonProps extends Omit<ButtonProps, "sx"> {
  to?: string;
  search?: string;
  target?: string;
  sx?: SxProps;
}

export const LinkButton = function (props: LinkButtonProps) {
  const sx = (theme: Theme) => ({
    fontWeight: "normal",
    fontSize: theme.fontSizes.smallFont,
    textTransform: "none",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
    },
  });

  const { variant, ...buttonProps } = props;

  const target = props.target != null ? props.target : "";

  if (props.to) {
    return (
      <Button
        sx={[sx, props.sx as any]}
        variant={variant || "text"}
        component={Link}
        target={target}
        to={{ pathname: props.to, search: props.search }}
        {...buttonProps}
      >
        {props.title}
      </Button>
    );
  }
  return (
    <Button sx={[sx, props.sx as any]} onClick={props.onClick} variant={variant || "text"} {...buttonProps}>
      {props.title}
    </Button>
  );
};

export const CancelButton = function (props: Omit<ButtonProps, "sx">) {
  const { size, ...restProps } = props;
  return (
    <Button
      size={size ? size : "medium"}
      onClick={props.onClick}
      sx={{
        fontWeight: "normal",
        textTransform: "none",
      }}
      {...restProps}
    >
      {props.title}
    </Button>
  );
};

export const DeleteButton = function (props: Omit<ButtonProps, "sx">) {
  const { size, ...restProps } = props;
  return (
    <Button
      size={size ? size : "medium"}
      onClick={props.onClick}
      sx={(theme) => ({
        fontWeight: 600,
        textTransform: "none",
        background: theme.colors.red,
        color: theme.palette.secondary.contrastText,
        "&:hover": {
          backgroundColor: theme.colors.darkRed,
        },
      })}
      {...restProps}
    />
  );
};

export const BackButton = function (props: Omit<ButtonProps, "variant">) {
  return (
    <Button
      variant={"text"}
      sx={[
        (theme) => ({
          color: "#3c3c3b",
          fontWeight: 400,
          fontSize: theme.fontSizes.mediumFont,
          textTransform: "none",
        }),
        props.sx as any,
      ]}
      {...props}
    >
      <BackIcon />
      {props.title}
    </Button>
  );
};
