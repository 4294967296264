import * as React from "react";
import { useState } from "react";
import { BaseTextFieldProps, Box, IconButton, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import { SectionLabel } from "./SectionLabel";

const classes = {
  root: {
    display: "contents",
    color: "#3c3c3b",
  },
  textField: {
    display: "contents",
  },
  clearButton: {
    opacity: 0.0,
  },
  focused: {
    opacity: 1.0,
  },
};

interface Props extends BaseTextFieldProps {
  classes?: any;
  showClearButton?: boolean;
  maxLength?: number;
  onReturnEnabled?: boolean;
  onReturnPressed?: () => any;
  label?: string;
  onChange?: (event: { target: { value: string } }) => void;
  hideClearIcon?: boolean;
}

function TextFieldWithLabel(props: Props) {
  const [focused, setFocused] = useState(false);
  const [valid, setValid] = useState(true);

  function validate(event: any) {
    const { value } = event.target;
    const required = props.required === true;
    const { type } = props;
    if (type != null) {
      if (required === false && value != null && value.length === 0) {
        setValid(true);
        return;
      }
      if (value != null) {
        if (type === "url") {
          const urlRegEx = /^((https?|ftp|smtp):\/\/)(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*/;
          setValid(urlRegEx.test(value.toLowerCase()));
        } else if (type === "email") {
          // eslint-disable-next-line no-control-regex
          const emailRegEx =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/;
          setValid(emailRegEx.test(value.toLowerCase()));
        }
      } else {
        setValid(true);
      }
    } else {
      setValid(true);
    }
  }

  const {
    label: title,
    onReturnPressed,
    showClearButton: pShowClearButton,
    error: pError,
    maxLength: pMaxLength,
    onReturnEnabled: pOnReturnEnabled,
    hideClearIcon,
    ...copiedProps
  } = props;

  const required = props.required != null && props.required === true;
  const hideIndicator = props.required == null || title?.isEmpty();
  const error = valid === false || pError === true || props.error === true;
  const supportsClearButton = pShowClearButton == null || pShowClearButton === true;
  const maximumLength = pMaxLength == null ? 100000 : pMaxLength;
  const onReturnEnabled = pOnReturnEnabled === null || pOnReturnEnabled === true;

  const { type } = props;

  const onchange = props.onChange;
  return (
    <Box sx={classes.root}>
      <SectionLabel
        required={error ? undefined : required}
        hideIndicator={error ? undefined : hideIndicator}
        error={error}
      >
        {title}
      </SectionLabel>
      <TextField
        {...copiedProps}
        error={error}
        inputProps={{ maxLength: maximumLength }}
        variant="outlined"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            if (onReturnPressed && onReturnEnabled) {
              event.preventDefault();
              event.stopPropagation();
              onReturnPressed();
            }
          }
          if (props.onKeyDown) {
            props.onKeyDown(event);
          }
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={(event) => {
          validate(event);
          setFocused(false);
        }}
        InputProps={{
          endAdornment: hideClearIcon ? undefined : (
            <InputAdornment position="end">
              <IconButton
                style={{ padding: 5 }}
                sx={
                  supportsClearButton && focused ? { ...classes.clearButton, ...classes.focused } : classes.clearButton
                }
                onClick={(event) => {
                  event.stopPropagation();
                  const valueChangeEvent = {
                    target: { value: type === "number" ? null : "" },
                  };
                  validate(valueChangeEvent);
                  onchange(valueChangeEvent);
                }}
                size="large"
              >
                <ClearIcon style={{ fontSize: 16 }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export default TextFieldWithLabel;
