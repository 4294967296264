import { BCEvent } from "../../model/event";
import { Button, Grid, SxProps } from "@mui/material";
import TextClamper from "../textClamper";
import { LoyaltyOutlined } from "@mui/icons-material";
import intl from "react-intl-universal";
import {
  activeOrPendingFamilyBonusClubOffers,
  eventHasActiveOrPendingFamilyBonusClubOffers,
} from "../../util/util_extensions";

interface Props {
  event: BCEvent;
  sx?: SxProps;
  onBonusClubOffersClicked: () => void;
}

export default function EventCardFamilyBonusClubOffersButton({ event, sx, onBonusClubOffersClicked }: Props) {
  const gap = { xs: 0.5, sm: 0.75, md: 0.75, lg: 1, xl: 1 };

  const offers = activeOrPendingFamilyBonusClubOffers({ event });

  const textIconButton = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        width: "100%",
        maxWidth: "100%",
        fontSize: "0.75rem",
      }}
    >
      <>
        <LoyaltyOutlined />
        <div>
          <TextClamper
            numberOfLines={1}
            text={intl.get("company_events.event_card.button_family_bonus_club_offers")}
            center
            fixedHeight
          />
          {offers.length === 1 && intl.get("company_events.event_card.subtitle_family_bonus_club_offers_single")}
          {offers.length > 1 &&
            intl.get("company_events.event_card.subtitle_family_bonus_club_offers_multi", { count: offers.length })}
        </div>
      </>
    </div>
  );

  return (
    <Grid container sx={sx} columnSpacing={gap}>
      <Grid item xs={12} display={"flex"}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onBonusClubOffersClicked();
          }}
          color={eventHasActiveOrPendingFamilyBonusClubOffers({ event }) ? "primary" : "primaryLight"}
          sx={[
            sx as any,
            () => ({
              flexGrow: 1,
              minWidth: 0,
              minHeight: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 2,
              paddingBottom: 2,
              backgroundColor: undefined,
              background: undefined,
              font: "normal normal bold 12px/13px Manrope",
            }),
          ]}
        >
          {textIconButton() as any}
        </Button>
      </Grid>
    </Grid>
  );
}
