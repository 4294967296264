import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import PrivatePermissionGuard from "../components/router/PrivatePermissionGuard";
import DashboardPage from "../pages/DashboardPage";
import PrivateGuard from "../components/router/PrivateGuard";
import SetupProfilePage from "../pages/SetupProfilePage";
import UserPage from "../pages/UserEditPage";
import GlobalReportingPage from "../pages/GlobalReportingPage";
import CompaniesPages from "../pages/CompaniesPage";
import AdminUsersPage from "../pages/AdminUsersPage";
import AddAdminUserPage from "../pages/AddAdminUserPage";
import InboxPage from "../pages/InboxPage";
import PrivateCompanyPermissionGuard from "../components/router/PrivateCompanyPermissionGuard";
import CompanyEditPage from "../pages/company/CompanyEditPage";
import CompanyEventsPage from "../pages/company/CompanyEventsPage";
import CompanyLocationsPage from "../pages/company/CompanyLocationsPage";
import CompanyActivityLogsPage from "../pages/company/CompanyActivityLogsPage";
import CompanySharedLocationEditPage from "../pages/company/CompanySharedLocationEditPage";
import EventEditPage from "../pages/event/EventEditPage";
import EventReviewPage from "../pages/event/EventReviewPage";
import EventTransferPage from "../pages/event/EventTransferPage";
import CompanyUsersPage from "../pages/company/CompanyUsersPage";
import AddCompanyUserPage from "../pages/company/AddCompanyUserPage";
import CompanyMediaPage from "../pages/company/CompanyMediaPage";
import CompanyPaymentPage from "../pages/company/CompanyPaymentPage";
import ResellerSalespartnerEarningsPage from "../pages/ResellerSalespartnerEarningsPage";
import CompanyReportingPage from "../pages/company/CompanyReportingPage";
import AdminCompanyCreditsPage from "../pages/company/AdminCompanyCreditsPage";
import PaymentPlansPage from "../pages/PaymentPlansPage";
import PaymentPlanEditPage from "../pages/PaymentPlanEditPage";
import ResellerProfilesPage from "../pages/ResellerProfilesPage";
import ResellerProfileEditPage from "../pages/ResellerProfileEditPage";
import SeoPagesPage from "../pages/SeoPagesPage";
import SeoPageEditPage from "../pages/SeoPageEditPage";
import PluginApiKeysPage from "../pages/PluginApiKeysPage";
import GlobalInvoicesPages from "../pages/GlobalInvoicesPages";
import NavigateWithSearch from "../components/router/NavigateWithSearch";
import * as React from "react";
import CompanyInvoicesPage from "../pages/company/CompanyInvoicesPage";
import { RouterProvider } from "react-router-dom";
import RouterAppOutletShell from "./RouterAppOutletShell";
import FamilyBonusClubInboxPage from "../pages/fbc/FamilyBonusClubInboxPage";
import CompanyFamilyBonusClubOffersPage from "../pages/fbc/CompanyFamilyBonusClubOffersPage";
import CompanyFamilyBonusClubOfferEditPage from "../pages/fbc/CompanyFamilyBonusClubOfferEditPage";
import { FamilyBonusClubReviewPage } from "../pages/fbc/FamilyBonusClubReviewPage";

export default function AppRouter() {
  return <RouterProvider router={router} />;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RouterAppOutletShell />}>
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
      <Route path="/resetPassword/:resetCode" element={<ResetPasswordPage />} />

      <Route
        path={"/dashboard"}
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_COMPANIES", "LIST_MY_COMPANIES"]}>
            <DashboardPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path={"/setupProfile"}
        element={
          <PrivateGuard ignoreSetupNeeded>
            <SetupProfilePage />
          </PrivateGuard>
        }
      />

      <Route
        path="/user/:company_id"
        element={
          <PrivateGuard>
            <UserPage />
          </PrivateGuard>
        }
      />

      <Route
        path="/reporting"
        element={
          <PrivatePermissionGuard anyPermission={["SHOW_GLOBAL_REPORT"]}>
            <GlobalReportingPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/companies"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_COMPANIES", "LIST_MY_COMPANIES"]}>
            <CompaniesPages />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/users"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_USERS"]}>
            <AdminUsersPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/users/new"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_USERS"]}>
            <AddAdminUserPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/inbox"
        element={
          <PrivatePermissionGuard anyPermission={["REVIEW_COMPANY_EVENTS"]}>
            <InboxPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/family-bonus-card-offer-inbox"
        element={
          <PrivatePermissionGuard anyPermission={["REVIEW_FAMILY_BONUS_CLUB_OFFERS"]}>
            <FamilyBonusClubInboxPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/family-bonus-card-offer-inbox/:offer_id"
        element={
          <PrivatePermissionGuard anyPermission={["REVIEW_FAMILY_BONUS_CLUB_OFFERS"]}>
            <FamilyBonusClubReviewPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/edit"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["MANAGE_COMPANIES", "EDIT_COMPANY_INFO"]}>
            <CompanyEditPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/new"
        element={
          <PrivateGuard>
            <CompanyEditPage />
          </PrivateGuard>
        }
      />

      <Route
        path="/companies/:company_id/company_events"
        element={
          <PrivateCompanyPermissionGuard
            anyPermission={[
              "SHOW_COMPANY_EVENTS",
              "EDIT_COMPANY_EVENTS",
              "CREATE_COMPANY_EVENTS",
              "REVIEW_COMPANY_EVENTS",
            ]}
          >
            <CompanyEventsPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/family-bonus-card"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["MANAGE_FAMILY_BONUS_CLUB_OFFERS"]}>
            <CompanyFamilyBonusClubOffersPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/family-bonus-card/new/:event_id"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["MANAGE_FAMILY_BONUS_CLUB_OFFERS"]}>
            <CompanyFamilyBonusClubOfferEditPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/family-bonus-card/:offer_id/edit"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["MANAGE_FAMILY_BONUS_CLUB_OFFERS"]}>
            <CompanyFamilyBonusClubOfferEditPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/company_shared_locations"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["EDIT_COMPANY_EVENTS", "CREATE_COMPANY_EVENTS"]}>
            <CompanyLocationsPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/activity_logs"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["MANAGE_COMPANIES"]}>
            <CompanyActivityLogsPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/company_shared_locations/new"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["EDIT_COMPANY_EVENTS", "CREATE_COMPANY_EVENTS"]}>
            <CompanySharedLocationEditPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/company_shared_locations/:location_id/edit"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["EDIT_COMPANY_EVENTS", "CREATE_COMPANY_EVENTS"]}>
            <CompanySharedLocationEditPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/company_events/new"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["CREATE_COMPANY_EVENTS"]}>
            <EventEditPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/company_events/:event_id/edit"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["EDIT_COMPANY_EVENTS", "SHOW_COMPANY_EVENTS"]}>
            <EventEditPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/company_events/:event_id/review"
        element={
          <PrivatePermissionGuard anyPermission={["REVIEW_COMPANY_EVENTS"]}>
            <EventReviewPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/company_events/:event_id/transfer"
        element={
          <PrivatePermissionGuard anyPermission={["TRANSFER_EVENTS"]}>
            <EventTransferPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/company_info"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["SHOW_COMPANY_INFO", "EDIT_COMPANY_INFO"]}>
            <CompanyEditPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/users"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["SHOW_COMPANY_USERS", "MANAGE_COMPANY_USERS"]}>
            <CompanyUsersPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/users/new"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["MANAGE_COMPANY_USERS"]}>
            <AddCompanyUserPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/media"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["SHOW_COMPANY_EVENTS"]}>
            <CompanyMediaPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/plan"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["SHOW_COMPANY_PAYMENT_DATA", "EDIT_COMPANY_PAYMENT_DATA"]}>
            <CompanyPaymentPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/invoices"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["SHOW_COMPANY_PAYMENT_DATA", "EDIT_COMPANY_PAYMENT_DATA"]}>
            <CompanyInvoicesPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/salespartner-earnings"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["SHOW_SALESPARTNER_EARNINGS"]}>
            <ResellerSalespartnerEarningsPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/reporting"
        element={
          <PrivateCompanyPermissionGuard anyPermission={["EDIT_COMPANY_EVENTS", "SHOW_COMPANY_EVENTS"]}>
            <CompanyReportingPage />
          </PrivateCompanyPermissionGuard>
        }
      />

      <Route
        path="/companies/:company_id/admin_credits"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_PAYMENT_PLANS"]}>
            <AdminCompanyCreditsPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/paymentplans"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_PAYMENT_PLANS"]}>
            <PaymentPlansPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/paymentplans/new"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_PAYMENT_PLANS"]}>
            <PaymentPlanEditPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/paymentplans/:product_id"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_PAYMENT_PLANS"]}>
            <PaymentPlanEditPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/commission_management"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_RESELLER_PROFILES"]}>
            <ResellerProfilesPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/commission_management/:user_id"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_RESELLER_PROFILES", "SHOW_RESELLER_PROFILE"]}>
            <ResellerProfileEditPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/resellers/:user_id/earnings"
        key="reseller-earnings"
        element={
          <PrivatePermissionGuard anyPermission={["SHOW_RESELLER_EARNINGS"]}>
            <ResellerSalespartnerEarningsPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/resellers/:user_id/credit-notes"
        key="reseller-credit-notes"
        element={
          <PrivatePermissionGuard anyPermission={["SHOW_RESELLER_CREDIT_NOTES"]}>
            <ResellerSalespartnerEarningsPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/reseller-earnings"
        key="global-reseller-earnings"
        element={
          <PrivatePermissionGuard anyPermission={["SHOW_GLOBAL_RESELLER_EARNINGS"]}>
            <ResellerSalespartnerEarningsPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/reseller-creditnotes"
        key="global-reseller-creditnotes"
        element={
          <PrivatePermissionGuard anyPermission={["SHOW_GLOBAL_RESELLER_CREDIT_NOTES"]}>
            <ResellerSalespartnerEarningsPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/salespartner-earnings"
        key="global-salespartner-earnings"
        element={
          <PrivatePermissionGuard anyPermission={["SHOW_GLOBAL_SALESPARTNER_EARNINGS"]}>
            <ResellerSalespartnerEarningsPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/seopages"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_SEO_PAGES"]}>
            <SeoPagesPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/seopages/new"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_SEO_PAGES"]}>
            <SeoPageEditPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/seopages/:seopage_id"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_SEO_PAGES"]}>
            <SeoPageEditPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/plugin_api_keys"
        element={
          <PrivatePermissionGuard anyPermission={["MANAGE_PLUGIN_API_KEYS"]}>
            <PluginApiKeysPage />
          </PrivatePermissionGuard>
        }
      />

      <Route
        path="/global_invoices"
        element={
          <PrivatePermissionGuard anyPermission={["SHOW_GLOBAL_INVOICES"]}>
            <GlobalInvoicesPages />
          </PrivatePermissionGuard>
        }
      />

      <Route path="/" element={<LandingPage />} />

      <Route element={<NavigateWithSearch to={"/"} />} />
    </Route>,
  ),
);
