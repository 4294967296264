import { useDispatch } from "react-redux";
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { PageTracker } from "../../util/pageTracker";
import { FamilyBonusClubOfferForm } from "./FamilyBonusClubOfferForm";
import AppStyles from "../../styles/appStyles";
import { BackButton } from "../../components/Buttons";
import intl from "react-intl-universal";
import {
  FbcOffer,
  FbcOfferData,
  FbcOfferDataVersion,
  FbcOfferPersonGroup,
  FbcOfferReviewStatus,
  FbcOfferStatus,
} from "../../model/fbc/fbc.offer";
import { Box, Grid, Paper, Stack, Tabs, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as FamilyBonusClubActions from "../../actions/family.bonus.club.actions";
import FamilyBonusClubHeaderActions from "./FamilyBonusClubHeaderActions";
import useSelectedCompany from "../../hooks/useSelectedCompany";
import { asNonEmptyString } from "../../util/util_extensions";
import EventFormTab from "../../components/eventEdit/EventFormTab";
import TabGeneralIcon from "../../assets/icons/TabGeneral";

export default function CompanyFamilyBonusClubOfferEditPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const company = useSelectedCompany();
  const params = useParams();

  const [offer, setOffer] = useState<FbcOffer | null>();
  const [draftOfferData, setDraftOfferData] = useState<FbcOfferData>();
  const [maxUsagesUnlimited, setMaxUsagesUnlimited] = useState(false);
  const [maxUsagesPerDayUnlimited, setMaxUsagesPerDayUnlimited] = useState(false);
  const theme = useTheme();

  const [tab, setTab] = useState(0);
  function changeTab(event: any, newTab: any) {
    setTab(newTab);
  }

  function closePage(): void {
    navigate(-1);
  }

  const savePossible = () => {
    return (
      draftOfferData &&
      asNonEmptyString(draftOfferData.title) &&
      asNonEmptyString(draftOfferData.description) &&
      draftOfferData.validity_periods.length > 0 &&
      draftOfferData.savings_in_cents
    );
  };

  const saveChanges = () => {
    dispatch<any>(
      FamilyBonusClubActions.saveFamilyBonusClubOffer({
        company_id: company.company_id,
        offer_id: offer?.offer_id,
        event_id: params.event_id,
        data: draftOfferData,
        completion: () => {
          closePage();
        },
      }),
    );
  };

  useEffect(() => {
    if (params) {
      const companyId = params.company_id;
      const eventId = params.event_id;
      const offerId = params.offer_id;
      if (companyId && eventId) {
        // New Offer from event
        setMaxUsagesPerDayUnlimited(false);
        setMaxUsagesUnlimited(false);
        setDraftOfferData({
          title: "",
          version: FbcOfferDataVersion.draft,
          description: "",
          person_group: FbcOfferPersonGroup.all,
          self_redeemable: false,
          status: FbcOfferStatus.active,
          validity_periods: [],
          total_contingent: -1,
          max_usages_per_card: 1,
          max_usages_per_card_per_day: 1,
        });
      } else if (companyId && offerId) {
        // Edit Offer
        dispatch<any>(
          FamilyBonusClubActions.fetchOffer({
            company_id: companyId,
            offer_id: offerId,
            completion: (loadedOffer) => {
              setOffer(loadedOffer);
              let loadedDraftOfferData;
              if (loadedOffer.draft_offer) {
                loadedDraftOfferData = { ...loadedOffer.draft_offer };
              } else {
                const offerCopy = { ...loadedOffer.published_offer };
                delete offerCopy.entity_id;
                delete offerCopy.created_at;
                delete offerCopy.updated_at;
                loadedDraftOfferData = offerCopy;
              }
              if (loadedDraftOfferData.max_usages_per_card === -1) {
                setMaxUsagesUnlimited(true);
              }
              if (loadedDraftOfferData.max_usages_per_card_per_day === -1) {
                setMaxUsagesPerDayUnlimited(true);
              }
              setDraftOfferData(loadedDraftOfferData);
            },
          }),
        );
      }
    }
  }, []);

  const validationErrors = () => {
    if (!draftOfferData) {
      return [];
    }
    const errors = [];
    if (!asNonEmptyString(draftOfferData.title)) {
      errors.push(intl.get("family_bonus_club.offer_form.errors.missing_title"));
    }
    if (!asNonEmptyString(draftOfferData.description)) {
      errors.push(intl.get("family_bonus_club.offer_form.errors.missing_description"));
    }
    if (!draftOfferData.savings_in_cents) {
      errors.push(intl.get("family_bonus_club.offer_form.errors.missing_savings"));
    }
    if (draftOfferData.validity_periods.length === 0) {
      errors.push(intl.get("family_bonus_club.offer_form.errors.missing_validity_periods"));
    }
    if (
      draftOfferData.max_usages_per_card_per_day &&
      draftOfferData.max_usages_per_card &&
      draftOfferData.max_usages_per_card_per_day > draftOfferData.max_usages_per_card
    ) {
      errors.push(intl.get("family_bonus_club.offer_form.errors.usages_per_card_per_day_higher_than_total"));
    }
    return errors;
  };

  const renderValidationErrors = () => {
    return (
      <>
        {validationErrors().length > 0 && (
          <Paper sx={AppStyles.innerContentPaperError}>
            <Grid
              item
              container
              direction="column"
              sx={AppStyles.detailForm}
              style={{ paddingBottom: 0, paddingTop: 0 }}
            >
              <Grid item sx={AppStyles.noRightPadding}>
                <p
                  style={{
                    marginBottom: 0,
                    marginTop: 0,
                    paddingTop: 0,
                    paddingBottom: 10,
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                  }}
                >
                  <h5 style={{ color: "rgb(229,52,33)" }}>
                    {intl.get("family_bonus_club.offer_form.labels.validation_errors")}
                  </h5>

                  <ul>
                    {validationErrors().map((value) => {
                      return (
                        <li
                          dangerouslySetInnerHTML={{
                            __html: value,
                          }}
                        />
                      );
                    })}
                  </ul>
                </p>
              </Grid>
            </Grid>
          </Paper>
        )}
      </>
    );
  };

  const renderHintAboutChangeLimitations = () => {
    return (
      <>
        <Paper sx={AppStyles.innerContentPaperHint}>
          <Grid item container direction="column" sx={AppStyles.detailForm} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item sx={AppStyles.noRightPadding}>
              <p
                style={{
                  marginBottom: 0,
                  marginTop: 0,
                  paddingTop: 0,
                  paddingBottom: 10,
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                }}
              >
                <h5 style={{ color: theme.palette.primary.main }}>
                  {intl.get("family_bonus_club.offer_form.hint_change_review_limitation_title")}
                </h5>

                {intl.get("family_bonus_club.offer_form.hint_change_review_limitation_message")}
              </p>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };

  const renderReviewFeedback = () => {
    const hasReviewFeedback = offer && offer.review && offer.review.review_status === FbcOfferReviewStatus.rejected;
    return (
      <>
        {hasReviewFeedback && (
          <Paper sx={AppStyles.innerContentPaperError}>
            <Grid
              item
              container
              direction="column"
              sx={AppStyles.detailForm}
              style={{ paddingBottom: 0, paddingTop: 0 }}
            >
              <Grid item sx={AppStyles.noRightPadding}>
                <p
                  style={{
                    marginBottom: 0,
                    marginTop: 0,
                    paddingTop: 0,
                    paddingBottom: 10,
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                  }}
                >
                  <h5 style={{ color: "rgb(229,52,33)" }}>
                    {intl.get("family_bonus_club.offer_form.labels.review_feedback")}
                  </h5>

                  <ul>
                    {offer.review.review_feedback
                      .split("\n")
                      .filter((value) => value.length > 0)
                      .map((value) => {
                        return <li>{value}</li>;
                      })}
                  </ul>
                </p>
              </Grid>
            </Grid>
          </Paper>
        )}
      </>
    );
  };

  return (
    <>
      <PageTracker />

      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <Stack>
          <Stack direction="row" sx={[AppStyles.actionHeaderLeft, { marginRight: 2 }]} gap={"24px"} flexWrap={"wrap"}>
            <BackButton
              sx={{ marginRight: "auto" }}
              title={
                offer
                  ? intl.get("family_bonus_club.offer_form.title_edit")
                  : intl.get("family_bonus_club.offer_form.title_create")
              }
              onClick={() => {
                closePage();
              }}
            />

            <FamilyBonusClubHeaderActions onSave={saveChanges} isCreate={!offer} readonly={!savePossible()} />
          </Stack>
        </Stack>

        {renderReviewFeedback()}

        {renderValidationErrors()}
      </Box>

      {offer && offer.published_offer && (
        <Stack direction={"row"} justifyContent={"center"} sx={{ margin: 0 }}>
          <Tabs
            sx={AppStyles.innerContentPaper}
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={changeTab}
            variant={"scrollable"}
            scrollButtons="auto"
          >
            <EventFormTab icon={<TabGeneralIcon />} label={intl.get("family_bonus_club.tabs.tab_draft_version")} />
            <EventFormTab icon={<TabGeneralIcon />} label={intl.get("family_bonus_club.tabs.tab_published_version")} />
          </Tabs>
        </Stack>
      )}

      {renderHintAboutChangeLimitations()}

      {offer && offer.published_offer && tab === 1 && (
        <FamilyBonusClubOfferForm
          readonly={true}
          offerData={offer.published_offer}
          maxUsagesUnlimited={offer.published_offer.max_usages_per_card === -1}
          maxUsagesPerDayUnlimited={offer.published_offer.max_usages_per_card_per_day === -1}
        />
      )}

      {draftOfferData && tab === 0 && (
        <FamilyBonusClubOfferForm
          readonly={false}
          offerData={draftOfferData}
          onChangeOfferData={(data) => setDraftOfferData(data)}
          maxUsagesUnlimited={maxUsagesUnlimited}
          maxUsagesPerDayUnlimited={maxUsagesPerDayUnlimited}
          onSetMaxUsagesUnlimited={(value) => {
            setMaxUsagesUnlimited(value);
            if (value) {
              setDraftOfferData({ ...draftOfferData, max_usages_per_card: undefined });
            }
          }}
          onSetMaxUsagesPerDayUnlimited={(value) => {
            setMaxUsagesPerDayUnlimited(value);
            if (value) {
              setDraftOfferData({ ...draftOfferData, max_usages_per_card_per_day: undefined });
            }
          }}
        ></FamilyBonusClubOfferForm>
      )}
    </>
  );
}
