import intl from "react-intl-universal";
import { Theme } from "@mui/material";

export interface FbcOffer {
  offer_id: string;
  draft_offer?: FbcOfferData | null;
  published_offer?: FbcOfferData | null;
  review?: FbcOfferReview | null;
  event_title?: string | null;
  company_name?: string | null;
  company_id?: string | null;
}

export enum FbcOfferDataVersion {
  published = "PUBLISHED",
  draft = "DRAFT",
}

export enum FbcOfferPersonGroup {
  all = "ALL",
}

export enum FbcOfferStatus {
  active = "ACTIVE",
  inactive = "INACTIVE",
}

export interface FbcOfferData {
  entity_id?: string | null;
  version?: FbcOfferDataVersion | null;
  title: string;
  description: string;
  person_group: FbcOfferPersonGroup;
  total_contingent?: number | null;
  max_usages_per_card?: number | null;
  max_usages_per_card_per_day?: number | null;
  self_redeemable: boolean;
  created_at?: Date | null;
  updated_at?: Date | null;
  savings_in_cents?: number | null;
  status: FbcOfferStatus;
  validity_periods: FbcOfferDataValidityPeriod[];
}

export interface FbcOfferDataValidityPeriod {
  from: string;
  until: string;
  days: string;
}

export enum FbcOfferReviewStatus {
  pending = "PENDING",
  rejected = "REJECTED",
}

export enum FbcOfferReviewReason {
  reviewToActivate = "TO_ACTIVATE",
  reviewToDeactivate = "TO_DEACTIVATE",
}

export interface FbcOfferReview {
  review_status: FbcOfferReviewStatus;
  review_feedback: string;
  review_reason: FbcOfferReviewReason;
  review_submitted_at: Date;
}

export function formattedStatusForOffer({ offer }: { offer: FbcOffer }): string {
  if (offer.draft_offer) {
    if (
      offer.draft_offer.status === FbcOfferStatus.inactive &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.pending
    ) {
      return intl.get("family_bonus_club.event_offers_list.status.delete_pending");
    }
    if (
      offer.draft_offer.status === FbcOfferStatus.inactive &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.rejected
    ) {
      return intl.get("family_bonus_club.event_offers_list.status.delete_rejected");
    }
    if (
      offer.draft_offer.status === FbcOfferStatus.active &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.pending &&
      offer.published_offer === undefined
    ) {
      return intl.get("family_bonus_club.event_offers_list.status.create_pending");
    }
    if (
      offer.draft_offer.status === FbcOfferStatus.active &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.rejected &&
      offer.published_offer === undefined
    ) {
      return intl.get("family_bonus_club.event_offers_list.status.create_rejected");
    }
    if (
      offer.draft_offer.status === FbcOfferStatus.active &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.pending &&
      offer.published_offer !== undefined
    ) {
      return intl.get("family_bonus_club.event_offers_list.status.update_pending");
    }
    if (
      offer.draft_offer.status === FbcOfferStatus.active &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.rejected &&
      offer.published_offer !== undefined
    ) {
      return intl.get("family_bonus_club.event_offers_list.status.updated_rejected");
    }
  } else {
    return intl.get("family_bonus_club.event_offers_list.status.published");
  }
}

export function formattedStatusColorForOffer({ offer, theme }: { offer: FbcOffer; theme: Theme }): string {
  if (offer.draft_offer) {
    if (
      offer.draft_offer.status === FbcOfferStatus.inactive &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.pending
    ) {
      return theme.palette.warning.main;
    }
    if (
      offer.draft_offer.status === FbcOfferStatus.inactive &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.rejected
    ) {
      return theme.palette.error.main;
    }
    if (
      offer.draft_offer.status === FbcOfferStatus.active &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.pending &&
      offer.published_offer === undefined
    ) {
      return theme.palette.warning.main;
    }
    if (
      offer.draft_offer.status === FbcOfferStatus.active &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.rejected &&
      offer.published_offer === undefined
    ) {
      return theme.palette.error.main;
    }
    if (
      offer.draft_offer.status === FbcOfferStatus.active &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.pending &&
      offer.published_offer !== undefined
    ) {
      return theme.palette.warning.main;
    }
    if (
      offer.draft_offer.status === FbcOfferStatus.active &&
      offer.review &&
      offer.review.review_status === FbcOfferReviewStatus.rejected &&
      offer.published_offer !== undefined
    ) {
      return theme.palette.error.main;
    }
  } else {
    return theme.palette.secondary.main;
  }
}

export function changedOfferFields({ offer }: { offer: FbcOffer }): string[] {
  if (offer.draft_offer && offer.published_offer && offer.draft_offer.status !== FbcOfferStatus.inactive) {
    const changedFields: string[] = [];
    if (offer.draft_offer.title !== offer.published_offer.title) {
      changedFields.push("title");
    }
    if (offer.draft_offer.description !== offer.published_offer.description) {
      changedFields.push("description");
    }
    if (offer.draft_offer.max_usages_per_card !== offer.published_offer.max_usages_per_card) {
      changedFields.push("max_usages_per_card");
    }
    if (offer.draft_offer.max_usages_per_card_per_day !== offer.published_offer.max_usages_per_card_per_day) {
      changedFields.push("max_usages_per_card_per_day");
    }
    if (offer.draft_offer.validity_periods.length !== offer.published_offer.validity_periods.length) {
      changedFields.push("validity_periods");
    } else {
      for (
        let index = 0;
        index < offer.draft_offer.validity_periods.length && changedFields.includes("validity_period") === false;
        index++
      ) {
        const draft = offer.draft_offer.validity_periods[index];
        const published = offer.published_offer.validity_periods[index];
        if (draft.from !== published.from || draft.until !== published.until || draft.days !== published.days) {
          changedFields.push("validity_period");
        }
      }
    }
    return changedFields;
  }
  return [];
}
