import React from "react";
import { Grid } from "@mui/material";
import { GridSize } from "@mui/material/Grid/Grid";
import AppStyles from "../../../../styles/appStyles";

export function FormGridItem({
  md,
  children,
  noTopPadding,
  smallPadding,
}: {
  md?: GridSize;
  children: any;
  noTopPadding?: boolean;
  smallPadding?: boolean;
}) {
  const smallPaddingSx =
    noTopPadding === true ? AppStyles.detailFormRowNoBorderNoTopPaddingSmall : AppStyles.detailFormRowNoBorderSmall;
  const regularSx =
    noTopPadding === true ? AppStyles.detailFormRowNoBorderNoTopPadding : AppStyles.detailFormRowNoBorder;
  return (
    <Grid
      item
      xs={12}
      md={md ?? 12}
      container
      direction={"column"}
      sx={smallPadding === true ? smallPaddingSx : regularSx}
    >
      {children}
    </Grid>
  );
}
