import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { CompanyState } from "../model/companyState";
import { updateStateFieldByProp } from "../util/util_extensions";

export const companyState = createReducer(
  {
    selected_company_id: null,
    company: null,
    allCompanies: null,
    allSalesPartners: null,
    paging: {
      page_size: 10,
      current_page: 1,
    },
    searchCriteria: {
      text: "",
      includeDeleted: false,
      onlyCompaniesWithSuspendedUpgrades: false,
      onlyCompaniesWithActiveSubscriptions: false,
      onlyCompaniesWithUnusedCredits: false,
      onlyCompaniesWithUnusedPremiumSlots: false,
      onlyCompaniesWithUnusedPremiumPlusSlots: false,
      onlyCompaniesWithFbcMembership: false,
    },
    transferCompanies: [],
    transferEvent: null,
  },
  {
    [ActionType.SELECT_COMPANY](state: CompanyState, action: Action<any>) {
      return {
        ...state,
        selected_company_id: action.payload.selected_company_id,
        show_company_detail_menu: action.payload.show_company_detail_menu,
        company: null,
        transferCompanies: [],
        transferEvent: null,
      };
    },
    [ActionType.UPDATE_COMPANIES_SEARCH_CRITERIA](state: CompanyState, action: Action<any>) {
      return updateStateFieldByProp({ ...state, error: null }, action.payload.prop, action.payload.value);
    },
    [ActionType.FETCH_COMPANIES](state: CompanyState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          allCompanies: action.payload.companies,
          paging: action.payload.paging,
        };
      }
      return { ...state };
    },
    [ActionType.FETCH_SALESPARTNERS](state: CompanyState, action: Action<any>) {
      return {
        ...state,
        allSalesPartners: action.payload.salesPartners,
      };
    },
    [ActionType.FETCH_COMPANY](state: CompanyState, action: Action<any>) {
      if (action.payload.data === true && action.payload.company.company_id === state.selected_company_id) {
        const { company } = action.payload;
        return { ...state, company };
      }
      return { ...state };
    },
    [ActionType.CLOSE_COMPANY](state: CompanyState) {
      if (state.company !== null || state.selected_company_id !== null || state.show_company_detail_menu !== null) {
        return {
          ...state,
          company: null,
          selected_company_id: null,
          show_company_detail_menu: null,
          transferCompanies: [],
          transferEvent: null,
        };
      }
      return state;
    },
    [ActionType.SAVE_COMPANY](state: CompanyState, action: Action<any>) {
      if (action.payload.company) {
        return { ...state, company: action.payload.company };
      }
      return state;
    },
    [ActionType.FETCH_TRANSFER_COMPANIES](state: CompanyState, action: Action<any>) {
      return {
        ...state,
        transferCompanies: action.payload.transferCompanies,
        transferEvent: action.payload.event,
      };
    },
  },
);
