import { DialogHandler } from "../../components/dialog/useDialogHandler";
import HandlerDialog from "../../components/dialog/handlerDialog";
import * as React from "react";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import intl from "react-intl-universal";
import { FbcOffer } from "../../model/fbc/fbc.offer";

export default function DialogDeleteFamilyBonusClubOfferConfirmation({
  handler,
  onConfirm,
}: {
  handler: DialogHandler<FbcOffer>;
  onConfirm: (offer: FbcOffer) => void;
}) {
  return (
    <HandlerDialog handler={handler}>
      <Content
        onConfirm={() => onConfirm(handler.payload)}
        onCancel={handler.close}
        offerTitle={handler.payload?.draft_offer?.title ?? handler.payload?.published_offer?.title ?? ""}
      />
    </HandlerDialog>
  );
}

function Content({
  onConfirm,
  onCancel,
  offerTitle,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  offerTitle: string;
}) {
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {intl.get("family_bonus_club.offers.delete_confirmation.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          dangerouslySetInnerHTML={{
            __html: intl.get("family_bonus_club.offers.delete_confirmation.message", {
              offer_title: offerTitle,
            }),
          }}
        ></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {intl.get("family_bonus_club.offers.delete_confirmation.action_cancel")}
        </Button>
        <Button onClick={onConfirm} color="error" autoFocus>
          {intl.get("family_bonus_club.offers.delete_confirmation.action_confirm")}
        </Button>
      </DialogActions>
    </>
  );
}
