import RejectIcon from "@mui/icons-material/Close";
import intl from "react-intl-universal";
import ApproveIcon from "@mui/icons-material/Check";
import * as React from "react";
import { useDispatch } from "react-redux";
import { FbcOfferReviewReason } from "../../model/fbc/fbc.offer";
import { BackButton, DefaultButton } from "../../components/Buttons";
import * as FamilyBonusClubActions from "../../actions/family.bonus.club.actions";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import AppStyles from "../../styles/appStyles";
import EditIcon from "@mui/icons-material/Edit";

interface Props {
  onReviewFinished: () => void;
  rejectionDescription: string;
  offerId: string;
  companyId: string;
  reviewReason: FbcOfferReviewReason;
  isOfferUpdate: boolean;
}

export default function FamilyBonusClubReviewHeaderActions({
  onReviewFinished,
  rejectionDescription,
  offerId,
  companyId,
  reviewReason,
  isOfferUpdate,
}: Props) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  async function rejectFamilyBonusClubOffer() {
    dispatch<any>(
      FamilyBonusClubActions.rejectOffer({
        offer_id: offerId,
        reason: rejectionDescription,
        completion: (success) => {
          if (success) {
            onReviewFinished();
          }
        },
      }),
    );
  }

  async function approveFamilyBonusClubOffer() {
    dispatch<any>(
      FamilyBonusClubActions.approveOffer({
        offer_id: offerId,
        completion: (success) => {
          if (success) {
            onReviewFinished();
          }
        },
      }),
    );
  }

  const navigateToOfferEditing = () => {
    navigate(`/companies/${companyId}/family-bonus-card/${offerId}/edit`);
  };

  return (
    <Stack
      direction="row"
      sx={[AppStyles.actionHeaderLeft, { marginRight: 2, marginLeft: 2 }]}
      gap={"24px"}
      flexWrap={"wrap"}
    >
      <BackButton
        sx={{ marginRight: "auto" }}
        title={intl.get("family_bonus_club.review.action_back_to_inbox")}
        onClick={() => {
          navigate("/family-bonus-card-offer-inbox");
        }}
      />

      <Stack direction={"row"} gap={"24px"}>
        <DefaultButton color={"secondary"} onClick={navigateToOfferEditing}>
          <EditIcon />
          {intl.get("family_bonus_club.review.action_edit_offer")}
        </DefaultButton>

        <DefaultButton color={"error"} onClick={rejectFamilyBonusClubOffer}>
          <RejectIcon />
          {reviewReason === FbcOfferReviewReason.reviewToActivate
            ? isOfferUpdate
              ? intl.get("family_bonus_club.review.action_activate_reject_updated_offer")
              : intl.get("family_bonus_club.review.action_activate_reject_new_offer")
            : intl.get("family_bonus_club.review.action_deactivate_reject")}
        </DefaultButton>
        <DefaultButton onClick={approveFamilyBonusClubOffer}>
          <ApproveIcon />
          {reviewReason === FbcOfferReviewReason.reviewToActivate
            ? isOfferUpdate
              ? intl.get("family_bonus_club.review.action_activate_approve_updated_offer")
              : intl.get("family_bonus_club.review.action_activate_approve_new_offer")
            : intl.get("family_bonus_club.review.action_deactivate_approve")}
        </DefaultButton>
      </Stack>
    </Stack>
  );
}
