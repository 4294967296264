import { Button, Grid, Paper, Typography } from "@mui/material";
import AppStyles from "../../styles/appStyles";
import * as React from "react";

export enum OnboardingImageType {
  eventOnboarding = "onboarding_image.png",
  familyBonusCardOnboarding = "onboarding_image_fbc.png",
}

export default function CallToActionHeaderBanner({
  title,
  text,
  actionTitle,
  onAction,
  imageType,
}: {
  title: string;
  text: string;
  actionTitle: string;
  onAction: () => void;
  imageType: OnboardingImageType;
}) {
  return (
    <Paper
      sx={{
        ...AppStyles.centerPaper,
        ...AppStyles.innerContentPaper,
        boxShadow: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "34px",
        paddingBottom: "34px",
        backgroundImage: 'url("/onboarding_background.png")',
        backgroundSize: "100% 100%",
        resize: "both",
        marginLeft: 1,
        marginRight: 0,
      }}
    >
      <Grid container direction={"row"} spacing={16}>
        <Grid item xs={4} container direction={"column"} gap={4} sx={{}}>
          <Typography
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              fontSize: theme.fontSizes.largerFont,
              fontWeight: 800,
              lineHeight: "2.18rem",
              maxWidth: "230px",
            })}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Typography
            dangerouslySetInnerHTML={{ __html: text }}
            sx={(theme) => ({
              maxWidth: "230px",
              fontSize: theme.fontSizes.biggerFont,
            })}
          />
          <Button onClick={() => onAction()} color={"secondary"} size={"large"}>
            {actionTitle}
          </Button>
        </Grid>
        <Grid item xs={8}>
          <img src={`/${imageType.toString()}`} />
        </Grid>
      </Grid>
    </Paper>
  );
}
