import * as React from "react";
import { styled } from "@mui/material/styles";
import { FormLabel } from "@mui/material";
import * as intl from "react-intl-universal";

const PREFIX = "SectionLabel";

const classes = {
  sectionHeaderLabel: `${PREFIX}-sectionHeaderLabel`,
  sectionHeaderSubLabel: `${PREFIX}-sectionHeaderSubLabel`,
  sectionHeaderMandatoryIndicator: `${PREFIX}-sectionHeaderMandatoryIndicator`,
};

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  [`&.${classes.sectionHeaderLabel}`]: {
    fontWeight: theme.headerFontWeight,
    fontSize: theme.fontSizes.mediumFont,
    fontFamily: theme.headerFontFamily,
  },

  [`& .${classes.sectionHeaderSubLabel}`]: {
    fontWeight: 500,
    fontSize: theme.fontSizes.largeFont,
    display: "inline",
  },
}));

const StyledMandatoryIndicator = styled("span")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  fontSize: theme.fontSizes.largeFont,
  display: "inline",
}));

const aSectionLabel = function (props: {
  classes?: any;

  children?: any;
  required?: boolean;
  hideIndicator?: boolean;
  error?: boolean;
}) {
  const {} = props;
  const hideIndicator = props.hideIndicator == null ? false : props.hideIndicator;
  const error = props.error == null ? false : props.error;
  if (props.required === null || props.required === undefined) {
    return (
      <StyledFormLabel className={classes.sectionHeaderLabel} error={error}>
        {props.children}
      </StyledFormLabel>
    );
  }

  return (
    <FormLabel className={classes.sectionHeaderLabel} error={error}>
      {props.required ? (
        <FormLabel>
          <b>
            {props.children}
            {!hideIndicator && (
              <StyledMandatoryIndicator>{intl.get("form.mandatory_indicator")}</StyledMandatoryIndicator>
            )}
          </b>
        </FormLabel>
      ) : (
        <>
          <FormLabel>
            {props.children}
            {!hideIndicator && <span className={classes.sectionHeaderSubLabel}>&nbsp;</span>}
          </FormLabel>
        </>
      )}
    </FormLabel>
  );
};

export default aSectionLabel;
export const SectionLabel = aSectionLabel;
