import { FbcOfferDataValidityPeriod } from "../../model/fbc/fbc.offer";
import { Box, Grid, IconButton, TableCell, ToggleButton, ToggleButtonGroup } from "@mui/material";
import AppStyles from "../../styles/appStyles";
import intl from "react-intl-universal";
import ClearIcon from "@mui/icons-material/Clear";
import * as React from "react";
import moment from "moment";
import { DatePicker } from "rsuite";
import { SectionLabel } from "../../components/SectionLabel";

export interface Props {
  validity_period: FbcOfferDataValidityPeriod;
  onRemove: () => void;
  disabled: boolean;
  onChange: (newValue: FbcOfferDataValidityPeriod) => void;
}

export default function FamilyBonusClubValidityPeriodEdit({ validity_period, onRemove, onChange, disabled }: Props) {
  function selectedDays() {
    if (validity_period.days) {
      return validity_period.days.split(",");
    }
    return [];
  }

  function changeSelectedDays(days: string[]) {
    onChange({ ...validity_period, days: days.join(",") });
  }

  const renderValidity = () => {
    const dataFormat = "YYYY-MM-DD";
    const displayFormat = "DD. MM.";

    const currentYear = moment(new Date()).locale("de").format("yyyy");
    const start = moment(validity_period.from).format("DD.MM");
    const end = moment(validity_period.until).format("DD.MM");
    const rangeStart = moment(`${start}.${currentYear}`, "DD.MM.yyyy");
    let rangeEnd = moment(`${end}.${currentYear}`, "DD.MM.yyyy");
    if (rangeEnd.isBefore(rangeStart)) {
      rangeEnd = rangeEnd.add(1, "year");
    }

    return (
      <Grid
        container
        direction="row"
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        justifyItems={"center"}
        gap={2}
      >
        <SectionLabel>von</SectionLabel>
        <DatePicker
          locale={{
            formattedMonthPattern: "MMMM",
            formattedDayPattern: "dd. MMMM",
            dateLocale: "de",
          }}
          format={"dd.MM.yyyy"}
          readOnly={disabled}
          isoWeek={true}
          value={rangeStart.toDate()}
          oneTap
          ranges={[]}
          cleanable={false}
          preventOverflow
          placement={"topStart"}
          renderValue={() => moment(rangeStart).format(displayFormat)}
          onSelect={(date) => {
            const fromDate = moment(date).locale("de").format(dataFormat);
            let untilDate = rangeEnd;
            if (rangeEnd.isBefore(untilDate)) {
              untilDate = untilDate.add(1, "year");
            }

            onChange({
              ...validity_period,
              from: fromDate,
              until: untilDate.locale("de").format(dataFormat),
            });
          }}
        />
        <SectionLabel>bis</SectionLabel>
        <DatePicker
          locale={{
            formattedMonthPattern: "MMMM",
            formattedDayPattern: "dd. MMMM",
            dateLocale: "de",
          }}
          format={"dd.MM.yyyy"}
          readOnly={disabled}
          isoWeek={true}
          value={rangeEnd.toDate()}
          oneTap
          ranges={[]}
          cleanable={false}
          preventOverflow
          placement={"topEnd"}
          renderValue={() => moment(rangeEnd).format(displayFormat)}
          onSelect={(date) => {
            onChange({
              ...validity_period,
              until: moment(date).locale("de").format(dataFormat),
            });
          }}
        />
      </Grid>
    );
  };

  return (
    <>
      <TableCell sx={{ padding: 0, paddingRight: 2 }} padding="none" align="left">
        {renderValidity()}
      </TableCell>
      <TableCell sx={{ padding: 0 }} padding="none" align="left">
        <Box sx={AppStyles.toggleContainer}>
          <ToggleButtonGroup
            value={selectedDays()}
            onChange={(event, value) => {
              changeSelectedDays(value);
            }}
          >
            {["mon", "tue", "wed", "thu", "fri", "sat", "sun", "hol"].map((day) => {
              return (
                <ToggleButton disabled={disabled} key={day} value={day}>
                  {intl.get(`days.long.${day}`)}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Box>
      </TableCell>

      {disabled === false && (
        <TableCell align="right">
          <IconButton
            onClick={() => {
              onRemove();
            }}
            color="error"
            size="large"
          >
            <ClearIcon />
          </IconButton>
        </TableCell>
      )}
    </>
  );
}
