import { CompanyState } from "../../model/companyState";
import { RootState } from "../../reducers";
import { bindActionCreators, compose } from "redux";
import { connect, useDispatch } from "react-redux";
import * as CompanyActions from "../../actions/company";
import { useNavigate } from "react-router-dom";
import { PageTracker } from "../../util/pageTracker";
import AppStyles from "../../styles/appStyles";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import intl from "react-intl-universal";
import { Box, Card, Grid, Stack, Table, Typography } from "@mui/material";
import * as React from "react";
import CallToActionHeaderBanner, { OnboardingImageType } from "../../components/events/CallToActionHeaderBanner";
import { DefaultButton } from "../../components/Buttons";
import { useEffect, useState } from "react";
import * as FamilyBonusClubActions from "../../actions/family.bonus.club.actions";
import { PagingInformation } from "../../model/pagingInformation";
import { BCEvent } from "../../model/event";
import EventCoverImage from "../../components/events/EventCoverImage";
import TextClamper from "../../components/textClamper";
import EventCardTypeAndStatus from "../../components/events/EventCardTypeAndStatus";
import { Pagination } from "../../components/TablePagination";
import useSelectedCompany from "../../hooks/useSelectedCompany";
import AddIcon from "@mui/icons-material/Add";
import {
  activeOrPendingFamilyBonusClubOffers,
  eventHasActiveOrPendingFamilyBonusClubOffers,
} from "../../util/util_extensions";
import { FamilyBonusClubOfferTableRow } from "./DialogFamilyBonusClubEventOffers";
import useDialogHandler from "../../components/dialog/useDialogHandler";
import { FbcOffer } from "../../model/fbc/fbc.offer";
import DialogDeleteFamilyBonusClubOfferConfirmation from "./DialogDeleteFamilyBonusClubOfferConfirmation";
import DialogJoinFamilyBonusClub from "./DialogJoinFamilyBonusClub";

export interface Props {
  companyState: CompanyState;
  actions: typeof CompanyActions;
}

function CompanyFamilyBonusClubOffersPage(props: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const company = useSelectedCompany();

  const [eventsWithOffers, setEventsWithOffers] = useState<BCEvent[]>([]);
  const [pagingInformation, setPagingInformation] = useState<PagingInformation>({
    page_size: 50,
    current_page: 1,
    total_pages: 100,
    total_size: 100,
  });

  const showFamilyBonusClubOnboarding = props.companyState.company.family_bonuscard.has_joined_fbc === false;
  const joinFamilyBonusClubDialogHandler = useDialogHandler<void>("joinFamilyBonusClubDialog");

  useEffect(() => {
    loadEvents();
  }, []);

  const loadEvents = () => {
    dispatch<any>(
      FamilyBonusClubActions.fetchEventsWithFamilyBonusClubOffers({
        company_id: company.company_id,
        page: pagingInformation.current_page,
        size: pagingInformation.page_size,
        completion: (loadedEvents, paging) => {
          setEventsWithOffers(loadedEvents);
          setPagingInformation(paging);
        },
      }),
    );
  };

  const deleteFamilyBonusClubOfferHandler = useDialogHandler<FbcOffer>("deleteOffer");

  function joinFamilyBonusClub() {
    joinFamilyBonusClubDialogHandler.open();
  }

  const handleChangePage = (event: any, page: any) => {
    setPagingInformation({ ...pagingInformation, current_page: page + 1 });
    loadEvents();
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPagingInformation({ ...pagingInformation, page_size: event.target.value });
    loadEvents();
  };

  async function deleteFamilyBonusClubOffer({ offer_id }: { offer_id: string }) {
    dispatch<any>(
      FamilyBonusClubActions.deleteFamilyBonusClubOffer({
        company_id: company.company_id,
        offer_id,
        completion: () => {
          loadEvents();
        },
      }),
    );
  }

  return (
    <>
      <PageTracker />

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item sx={AppStyles.headerLeft} flexGrow={1}>
          <HeaderLabel>{intl.get("family_bonus_club.offers.title")}</HeaderLabel>
        </Grid>

        <Grid item sx={AppStyles.headerMid}>
          <Stack direction={"row"} gap={"22px"}></Stack>
        </Grid>
        <Grid item sx={AppStyles.headerRight}>
          <DefaultButton sx={AppStyles.buttonPlaceholder} />
        </Grid>
      </Grid>

      <Stack sx={{ padding: 2 }} gap={2}>
        {showFamilyBonusClubOnboarding && (
          <CallToActionHeaderBanner
            title={intl.get("family_bonus_club.onboarding_card.title")}
            text={intl.get("family_bonus_club.onboarding_card.text")}
            actionTitle={intl.get("family_bonus_club.onboarding_card.call_to_action")}
            imageType={OnboardingImageType.familyBonusCardOnboarding}
            onAction={() => {
              joinFamilyBonusClub();
            }}
          />
        )}
      </Stack>

      <Stack sx={{ padding: 2 }} gap={2}>
        {eventsWithOffers.map((event) => (
          <>
            <Card sx={{ maxHeight: "122px" }}>
              <Stack direction={"row"} sx={{ width: "100%" }} alignItems={"stretch"}>
                <EventCoverImage
                  event={event}
                  sx={{ aspectRatio: "217 / 122", minWidth: "217px", minHeight: "100%", maxHeight: "122px" }}
                />
                <Typography
                  sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    flexGrow: 1,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontWeight: 800,
                    font: "normal normal bold 18px/21px Manrope",
                  }}
                >
                  <TextClamper numberOfLines={2} text={event.title} />
                </Typography>

                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                  <EventCardTypeAndStatus event={event} sx={{ padding: 2, minWidth: 260 }} />
                </Box>
                {company.family_bonuscard.has_joined_fbc && (
                  <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ paddingRight: 2 }}>
                    <DefaultButton
                      color={"secondary"}
                      size={"large"}
                      onClick={() => {
                        navigate(`/companies/${company.company_id}/family-bonus-card/new/${event.event_id}`);
                      }}
                    >
                      <AddIcon />
                      {intl.get("family_bonus_club.offers.action_add")}
                    </DefaultButton>
                  </Box>
                )}
              </Stack>
            </Card>
            {eventHasActiveOrPendingFamilyBonusClubOffers({ event }) && (
              <Card sx={{ marginLeft: "217px" }}>
                <Table>
                  {activeOrPendingFamilyBonusClubOffers({ event }).map((value) => {
                    return (
                      <FamilyBonusClubOfferTableRow
                        withCreateDate={false}
                        offer={value}
                        onEdit={() => {
                          navigate(`/companies/${company.company_id}/family-bonus-card/${value.offer_id}/edit`);
                        }}
                        onDelete={() => {
                          deleteFamilyBonusClubOfferHandler.open(value);
                        }}
                      />
                    );
                  })}
                </Table>
              </Card>
            )}
          </>
        ))}
      </Stack>
      {pagingInformation.total_size > pagingInformation.page_size && (
        <Pagination
          paging={pagingInformation}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <DialogDeleteFamilyBonusClubOfferConfirmation
        handler={deleteFamilyBonusClubOfferHandler}
        onConfirm={(offer) => {
          deleteFamilyBonusClubOffer({ offer_id: offer.offer_id });
          deleteFamilyBonusClubOfferHandler.close();
        }}
      />

      <DialogJoinFamilyBonusClub
        handler={joinFamilyBonusClubDialogHandler}
        onFinishJoining={() => {
          joinFamilyBonusClubDialogHandler.close();
        }}
        onCancel={() => {
          joinFamilyBonusClubDialogHandler.close();
        }}
      />
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    companyState: state.companyState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyFamilyBonusClubOffersPage);
